@import url(https://fonts.googleapis.com/css?family=Josefin+Sans:100,200,300,regular,500,600,700,100italic,200italic,300italic,italic,500italic,600italic,700italic);
@font-face {
  font-family: "bold";   /*Can be any text*/
  src: local("Kabel-Black"),
    url("./fonts/Kabel-Black.otf") format("truetype");
}

* {
  margin: auto;
  padding: 0;
  box-sizing: border-box;
}

body {
  /* margin: 0; */
  font-family: -apple-system, BlinkMacSystemFont, 'Kabel-Black', 'Josefin Sans', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
