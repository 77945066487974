.about-layout
{
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  justify-content: center;
}

.half-Monstera
{
  width: fit-content;
  padding: 0;
  position: absolute;
  margin-top: 0px;
  margin-left: -10px;
  z-index: 0;
}

.about-container
{
  display: flex;
  flex-direction: column;
  position: inherit;
  top: 0;
  margin: auto;
  justify-content: center;
}

.about-us-container {
  text-align: left;
  width: 60%;
  margin-left: 30%;
  padding-right: 10%;
  margin-top: 200px;
}

.about-us-body {
  color: #385250;
  font-family: "Josefin Sans";
  font-size: 2rem;
}

#about-us-header {
  font-family: bold;
  color: #385250;
  font-size: 5rem;
}

.about-item
{
  margin: 5rem;
  text-align: center;
  text-shadow: 2px 4px 4px #FFF7E9;
}

.about-header
{
  font-style: 'Kabel-Black';
  font-weight: bolder;
  font-size: 3.5rem;
  padding-bottom: 1rem;
  color: #385250;
  text-align: left;

}

.about-header-manage {
  font-style: 'Kabel-Black';
  font-weight: bolder;
  font-size: 3.5rem;
  padding-bottom: 1rem;
  color: #385250;
  text-align: left;
  width: 700px;
}

.about-paragraph
{
  font-style: 'Josefin Sans';
  font-weight: 600;
  font-size: 2rem;
  text-align: left;
  color: #385250;
}

.care
{
  margin-top: 10%;
  margin-left: 40%;
  box-shadow: 5px 5px 10px grey;
  width: 600px;
  height: 600px;
  border-radius: 20px;
}

.share
{
  width: 90%;
  height: auto;
  margin-top: 10%;
  margin-right: 0;
  box-shadow: 5px 5px 10px grey;
}

.build-text
{
  margin-top: 10%;
  margin-left: 30%;
}

/* ================================= Meet Our Team CSS ====================================== */
.about-devs-container {
  position: relative;
  z-index: 1;
}

.meet-our-team-container {
  width: 90%;
  margin-top: 100px;
  text-align: center;
  z-index: 10;
}

.team-header-container {
  width: 600px;
  background-color: #FFF7E9;
  border-radius: 20px;
  padding: 10px;
}

#meet-our-team-header {
  font-family: bold;
  color: #385250;
  font-size: 5rem;
}

.team-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  z-index: 1;
}

.dev-container {
  margin-top: 50px;
  margin-bottom: 50px;
  color: #385250;
  font-family: "Josefin Sans";
  width: 400px;
}

.dev-image-container {
  width: 100%;
  height: 400px;
  border-radius: 20px;
}

.team-pic {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}

.dev-details-container {
  background-color: #FFF7E9;
  border-radius: 20px;
  padding: 10px;
}

.dev-name {
  font-weight: bold;
  font-size: 2rem;
}

.dev-role {
  font-size: 1.5rem;
}