/* ================================= Profile Page CSS ====================================== */
.profile-page {
    color: #385250;
  }
  
  .profile-left {
    margin-left: 5%;
    margin-right: 5%;
    float: left;
    width: 35%;
  }
  
  .profile-right {
    margin-right: 5%;
    float: left;
    width: 50%;
  }
  
  .profile-right > h1 {
    font-family: bold;
    margin-bottom: 20px;
  }
  
  .bio-container {
    display: grid;
    color: #385250;
  }
  
  .bio-container > p {
    font-family: "Josefin Sans";
  }
  
  .profile-header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }
  
  #pfp {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 200px;
    float: left;
    margin-right: 30px;
    margin-left: -100px;
  }
  
  #profile-username {
    float: left;
    color: #385250;
    font-family: bold;
    font-size: 3.2rem;
  }
  
  .profile-button {
    width: 45% !important;
    float: left !important;
    margin: 2.5%;
  }
  
  #profile-plantbase-link {
    text-decoration: none;
    color: #385250;
    font-family: bold;
  }
  
  .profile-plantbase-container {
    column-count: 3;
    column-gap: 10px;
    height: 100%;
    max-width: 1260;
    margin-top: 20px;
  }
  
  .profile-plantbase-container > .pin {
    width: 230px;
    border-radius: 16px;
    overflow: hidden;
    margin-bottom: 10px;
  }

  .profile-plantbase-container > .pin > .plant-pin-name-container {
    width: 220px;
    bottom: 10px;
    left: 5px;
  }

  .profile-plantbase-container > .pin-plant-name {
    font-size: 1rem;
  }
  
  @media (max-width: 1380px) {
    .profile-left {
      float: none;
      width: 100%;
      padding: 60px;
      padding-right: 140px;
    }
    
    .profile-right {
      float: none;
      width: 100%;
      padding: 60px;
    }

    .profile-plantbase-container {
      column-count: 4;
    }
  }


  @media (max-width: 1040px) {
    .profile-plantbase-container {
      column-count: 3;
    }
  }
  
  @media (max-width: 800px) {
    .profile-plantbase-container {
      column-count: 2;
    }
  }
  
  @media (max-width: 570px) {
    .profile-plantbase-container {
      column-count: 1;
    }
  }
  
  /* ========================================================================================= */

  /* ================================= Edit User Page CSS ====================================== */
  .edit-user-container {
    width: 600px;
    height: 750px;
    margin: 50px auto;
    position: relative;
    background-color: white;
    padding: 30px;
    border-radius: 30px;
    text-align: center;
    box-shadow: 5px 5px 10px grey;
    display: grid;
  }

  .edit-user-pic-and-preview {
    position: relative;
  }
  
  #edit-profile-pic {
    width: 100px;
    height: 100px;
    border-radius: 100px;
    float: left;
    object-fit: cover;
    margin: 30px;
    z-index: 20;
  }
  
  #user-uploaded-profile-pic {
    z-index: 10;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 100px;
    margin: 30px;
  }

  .edit-user-pic-container {
    width: 160px;
  }

  .change-pic-button-container {
    position: relative;
    width: 100%;
    height: 160px;
  }

  #change-pic-button {
    width: 160px !important;
  }

  .edit-user-name-container {
    width: 100%;
  }

  .edit-user-name-container-half {
    width: 45%;
    float: left;
    margin: 2.5%;
  }

  .edit-user-bio-container {
    width: 95%;
    height: 180px;
    margin-top: -25px;
  }

  #edit-user-bio {
    height: 80%;
  }

  .edit-user-username-container {
    width: 95%;
  }

  .edit-user-buttons-container {
    width: 50%;
  }

  .edit-user-buttons-container-half {
    width: 50%;
    float: left;
  }

  #edit-user-cancel-button {
    background: none;
    color: #385250;
  }

  /* ========================================================================================= */
