
/* =============================== Login and Register Page CSS======================================== */
  .login-page {
    text-align: center;
    height: 200vh;
    background-image: url("../img/Monstera.png");
    background-size: 90%;
    background-color: #FFF7E9;
    background-repeat: no-repeat;
    background-position: center center;
    padding-top: 10rem;
  }
  
  .register-page {
    text-align: center;
    height: 200vh;
    background-image: url("../img/Monstera.png");
    background-size: 90%;
    background-color: #FFF7E9;
    background-repeat: no-repeat;
    background-position: center center;
    padding-top: 10rem;
  }
  
  .login-container {
    width: 425px;
    margin: 100px auto;
    position: relative;
    background-color: white;
    padding: 30px;
    border-radius: 30px;
    text-align: center;
    box-shadow: 5px 5px 10px grey;
    z-index: 0;
  }

  .alert-container {
    width: 425px;
    margin: 100px auto;
    position: relative;
    background-color: white;
    padding: 30px;
    border-radius: 30px;
    text-align: center;
    box-shadow: 5px 5px 10px grey;
    z-index: 1;
  }
  
  .register-container {
    width: 900px;
    margin: 100px auto;
    position: relative;
    background-color: white;
    padding: 30px;
    border-radius: 30px;
    text-align: center;
    box-shadow: 5px 5px 10px grey;
  }
  
  .container-half {
    width: 50%;
    float: left;
  }
  
  .input-group {
    position: relative;
    margin: auto;
    width: 80%;
  }
  
  .login-register-swap {
    text-decoration: none;
    color: black;
    font-size: 1rem;
    font-weight: bold;
    color: green;
    text-decoration-line: underline;  
  }

  .forgot-password {
    text-decoration: none;
    margin-left: 60px !important;
    font-size: 1rem;
    font-weight: bold;
    margin-left: 5rem;
    color: green;
    text-decoration-line: underline;  
  }
  
  #register-button {
    width: 30%;
  }
  
  h2 {
    font-size: 1rem;
    margin: 10px;
  }
  
  hr {
    margin: 20px auto;
    width: 85%;
  }

  .error
  {
    text-decoration: none;
    color: red;
    font-size: 1rem;
    font-weight: bold;
    margin: 1rem;
  }

  .error-li
  {
    text-decoration: none;
    color: red;
    font-size: 1rem;
    font-weight: bold;
    text-align: left;
    margin: 1rem;
    
  }

  .success
  {
    text-decoration: none;
    color: green;
    font-size: 1rem;
    font-weight: bold;
  }
  
  /* ========================================================================================= */
  
  /* ================================ Input Type CSS ========================================= */
  label {
    color: black;
    display: block;
    margin-top: 1em;
    font-size: 1rem;
    font-weight: bold;
    text-align: left;
  }
  
  input[type = text] {
    border: 1px solid gray;
    width: 100%;
    padding: 1em;
    box-sizing: border-box;
    border-radius: 1em;
    margin-top: .5em;
    margin-bottom: 1.2em;
  }
  
  textarea[type = text] {
    border: 1px solid gray;
    width: 100%;
    padding: 1em;
    box-sizing: border-box;
    border-radius: 1em;
    margin-top: .5em;
    margin-bottom: 1.2em;
    resize: none;
  }
  
  input[type = email] {
    border: 1px solid gray;
    width: 100%;
    padding: 1em;
    box-sizing: border-box;
    border-radius: 1em;
    margin-top: .5em;
    margin-bottom: 1.2em;
  }
  
  textarea[type = text] {
    border: 1px solid gray;
    width: 100%;
    padding: 1em;
    box-sizing: border-box;
    border-radius: 1em;
    margin-top: .5em;
    margin-bottom: 1.2em;
    resize: none;
  }
  
  input[type = password] {
    border: 1px solid gray;
    width: 100%;
    padding: 1em;
    box-sizing: border-box;
    border-radius: 1em;
    margin-top: .5em;
    margin-bottom: 1.2em;
    font-family: Verdana;
  }
  
  input[type = button] {
    background: #385250;
    border: none;
    width: 70%;
    color: white;
    font-family: bold;
    font-weight: bold;
    margin-top: 1.2em;
    margin-bottom: 1.2em;
    padding: 1em 0;
    border-radius: 50px;
    font-size: 1.3em;
    cursor: pointer;
  }
  
  input[type = file] {
    cursor: pointer;
    opacity: 0%;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 11;
  }
  
  /* ========================================================================================= */
  